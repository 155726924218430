const hostname = typeof window !== 'undefined' ? window.location.hostname : '';

export const SESSION_CONSTANTS = {
  SESSION_ID: `${hostname}_SESSION_ID`,
  ACCESS_TOKEN: `${hostname}_ACCESS_TOKEN`,
  CAR_PAGE_STATUS: 'CAR_PAGE_STATUS',
  TW_PAGE_STATUS: 'TW_PAGE_STATUS',
  OPEN_HEALTH_FORM: 'OPEN_HEALTH_FORM',
  OPEN_TERM_FORM: 'OPEN_TERM_FORM',
  CAR_FORM_DATA: 'CAR_FORM_DATA',
  TW_FORM_DATA: 'TW_FORM_DATA',
  HEALTH_FORM_DATA: 'HEALTH_FORM_DATA',
  TERM_FORM_DATA: 'TERM_FORM_DATA',
  USER_MOBILE_NO:  'USER_MOBILE_NO'
} as const;

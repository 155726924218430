import { createSlice } from "@reduxjs/toolkit";
import { THealthSlice } from "../../../types/Health/THealthSlice";
import { HEALTH_REDUCERS } from "./HealthReducer";
import { formatNumberToLakhOrCrores } from "../../../SupportingFiles/HelpingFunction";

const initialState: THealthSlice = {
  REDIRECTED: false,
  QUOTE_LOADER: false,
  DROPDOWN_DATA: {
    TERM: [
      { label: "1 Year", value: "1" },
      { label: "2 Years", value: "2" },
      { label: "3 Years", value: "3" },
    ],
    SORT_BY: [
      { label: "Low-High", value: "Low-High" },
      { label: "High-Low", value: "High-Low" },
    ],
    AGE: [],
    SUM_INSURED: [
      { value: "1 Lakh", label: "100000" },
      { value: "2 Lakh", label: "200000" },
      { value: "4 Lakh", label: "400000" },
      { value: "5 Lakh", label: "500000" },
      { value: "6 Lakh", label: "600000" },
      { value: "7 Lakh", label: "700000" },
      { value: "8 Lakh", label: "800000" },
      { value: "9 Lakh", label: "900000" },
      { value: "10 Lakh", label: "1000000" },
      { value: "12 Lakh", label: "1200000" },
      { value: "15 Lakh", label: "1500000" },
      { value: "16 Lakh", label: "1600000" },
      { value: "17 Lakh", label: "1700000" },
      { value: "18 Lakh", label: "1800000" },
      { value: "19 Lakh", label: "1900000" },
      { value: "20 Lakh", label: "2000000" },
      { value: "25 Lakh", label: "2500000" },
      { value: "30 Lakh", label: "3000000" },
      { value: "40 Lakh", label: "4000000" },
      { value: "50 Lakh", label: "5000000" },
      { value: "60 Lakh", label: "6000000" },
      { value: "75 Lakh", label: "7500000" },
      { value: "1 Crore", label: "10000000" },
      { value: "1.5 Crore", label: "15000000" },
      { value: "2 Crore", label: "20000000" },
      { value: "3 Crore", label: "30000000" },
      { value: "6 Crore", label: "60000000" },
    ],
    OCCUPATION: [],
    MARTIAL: [],
    ADULT1_RELATIONSHIP: [],
    ADULT2_RELATIONSHIP: [
      { label: "Father", value: "Father" },
      { label: "Mother", value: "Mother" },
      { label: "Spouse", value: "Spouse" },
    ],
    CHILD_RELATIONSHIP: [],
    NOMINEE_RELATIONSHIP: [],
    GLOBALCOVERAGE: [
      { value: "Not Applicable", label: "Not Applicable" },
      { value: "WW Coverage", label: "WW Coverage" },
      { value: "WW Excl. US and Canada", label: "WW Excl. US and Canada" },
    ],
  },
  ADDON_DETAILS: {
    NCBSuper: false,
    field_34: false,
    SmartSelect: false,
    AirAmbulanceCover: false,
    CareShield: false,
    GlobalCoverage: { value: "", warning: false },
    field_43: false,
    field_AHC: false,
    field_IC: false,
    field_OPD: false,
    field_PED_tenure: { value: "1", warning: false },
    field_WB: false,
    field_CPW: false,
    field_DK: false,
    field_DUE: false,
  },
  ADD_FORM: {
    page_status : 1,
    spouse_relationship: { value: "", warning: false },
    age: { value: "", warning: false },
    children: "",
    gender: { value: "M", warning: false },
    pincode: { value: "", warning: false },
    whom_to_insure: "One Adult",
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    name: { value: "", warning: false },
    son_count: { value: "0", warning: false },
    daughter_count: { value: "0", warning: false },
    self_dob: { value: "", warning: false },
    spouse_dob: { value: "", warning: false },
    son_status: false,
    daughter_status: false,
    self_status: true,
    spouse_status: false,
    child_one_dob: { value: "", warning: false },
    child_two_dob: { value: "", warning: false },
    child_three_dob: { value: "", warning: false },
    child_four_dob: { value: "", warning: false },
    term_cond: { value: true, warning: false },
  },
  ADD_FORM_RESPONSE: {
    sum_insured: 0,
    quote_no: "",
    module: "",
    insurance_type: "",
    term: 0,
    cover: "",
    city: "",
    state: "",
    child: 0,
    adult: 0,
    mobile: 0,
    pincode: "",
    sort_by: "Low-High",
  },
  KYC_DETAILS: {
    propFullName: { value: "", warning: false },
    ProposerDob: { value: "", warning: false },
    verificationDoc: { value: "", warning: false },
    docID: { value: "", warning: false },
    cusdtomertype: { value: "", warning: false },
    gender: { value: "", warning: false },
    addressDoc: { value: "", warning: false },
    verificationDocFile: { value: "", warning: false },
    addressDocFile: { value: "", warning: false },
  },
  ALL_QUOTES_DATA: [],
  POLICY_TENURE: 1,
  SELECTED_QUOTE_DATA: {
    CashlessHospitalLists: [],
    sumInsured: "",
    productDetails: {
      brochure: "",
      buy_online_code: "",

      company_product_map: { company_code: "" },
      discount_calc_action: "",
      health_form_validation: "",
      id: 0,
      is_online: "",
      pre_existing_cover: "",
      prem_calc_action: "",
      product_code: "",
      product_type_code: "",
      product_desc: "",
      product_name: "",
      proposal_method: "",
      rate_calc_method: "",
      rating: "",
      room_cover: "",
    },
    premiumDetails: {
      basePremium: 0,
      finalPremium: 0,
      quoteId: "",
      serviceTax: 0,
    },
    SpecialFeatureLists: [
      { code: "", description: "", show_desc: 0, title: "" },
    ],
    CompanyDetails: {
      claim_ratio: "",
      company_code: "",
      logo: "",
      name: "",
      short_desc: "",
    },
    loading: true,
  },
  BUYACTION_RESPONSE_DATA: {
    quote_no: "",
    product_code: "",
    product_id: "",
    company_code: "",
    sum_insured: "",
    premium: "",
    insurance_type: "",
    quoteId: "",
    FinalPremiumm: "",
    ProductDetails: {
      id: 0,
      product_name: "",
      brochure: "",
      product_code: "",
      rate_calc_method: "",
      proposal_method: "",
      tax_calc_method: "",
      discount_calc_method: "",
      rating: 2,
      product_desc: "",
      buy_online_code: "",
      prem_calc_action: "",
      tax_calc_action: "",
      discount_calc_action: "0",
      health_form_validation: {
        self_min_age: 0,
        self_max_age: 0,
        spouse_min_age: 0,
        spouse_max_age: 0,
        child_min_age: 0,
        child_max_age: 0,
      },
    },
    CompanyDetails: {
      company_code: "",
      name: "",
      short_desc: "",
      logo: "",
      claim_ratio: "",
    },
    amountWithGst: "",
    term: "",
    ppterm: "",
    income: "",
    incomeOne: "",
    policyTenure: "",
  },
  INSURED_MEMBER_DETAILS: {
    NOMINEE__DETAILS: {
      full_name: { value: "", warning: false },
      dob: { value: "", warning: false },
      relationship: { value: "", warning: false },
      insured_member: true,
      prosper_details: true,
      localstate: false,
    },
    PROPOSER_DETAILS: {
      IS_PROPOSER_INSURED: true,
      name: { value: "", warning: false },
      occupation: { value: "", warning: false },
      dob: { value: "", warning: false },
      mobile: { value: "", warning: false },
      emergencyPhone: { value: "", warning: false },
      email: { value: "", warning: false },
      aadhar: { value: "", warning: false },
      gender: { value: "", warning: false },
      maritalStatus: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
      proposer_name: { value: "", warning: false },
      relationship: { value: "", warning: false },
      insurer_dob: { value: "", warning: false },
      pan_card: { value: "", warning: false },
    },
    ADULT_ONE_DETAILS: {
      name: { value: "", warning: false },
      occupation: { value: "", warning: false },
      dob: { value: "", warning: false },
      mobile: { value: "", warning: false },
      emergencyPhone: { value: "", warning: false },
      email: { value: "", warning: false },
      aadhar: { value: "", warning: false },
      gender: { value: "", warning: false },
      maritalStatus: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
      proposer_name: { value: "", warning: false },
      relationship: { value: "", warning: false },
      insurer_dob: { value: "", warning: false },
      pan_card: { value: "", warning: false },
    },
    ADULT_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_ONE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_THREE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_FOUR_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
  },
  NOMINEE_RELATIONSHIP_STATUS: "OTHER",
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    city: "",
    state: "",
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
  },
  MEDICAL_QUESTION_DATA_IIFCO: {
    question: [
      {
        main_question: {
          code: "PQ01",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Please select Yes if any member(s) to be insured have any Pre-Existing diseases other than Diabetes,High Blood Pressure,Asthma,Cholesterol?",
          status: false,
          member_data: [],
        },
        sub_question: [
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "High or low blood pressure",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Diabetes",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Thyroid disorder or any other endocrine disorder ?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you depend on insulin ?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title:
              "Any other existing disease or additional facts which effect the proposed insurance & should be closed to insurer ?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [],
              },
            ],
          },
        ],
      },
      {
        main_question: {
          code: "PQ08",
          icon_class: "hospitalized",
          title: "Smoking",
          status: false,
          description: "Does any of the members Smoke?",

          member_data: [
            {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Cigarettes per day",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Cigarettes per day",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Cigarettes per day",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Cigarettes per day",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Cigarettes per day",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Cigarettes per day",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
          ],
        },
        sub_question: [],
      },
      {
        main_question: {
          code: "PQ08",
          icon_class: "hospitalized",
          title: "Tobacco",
          status: false,
          description: "Do you Chew Pan Masala? (per week)",

          member_data: [
            {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Pouches per week",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Pouches per week",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Pouches per week",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Pouches per week",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Pouches per week",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Pouches per week",
                  value: "",
                  warning: false,
                  field_type: "dropdown",
                  dropdown_data: [
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                  ],
                  numbers_only: false,
                },
              ],
            },
          ],
        },
        sub_question: [],
      },
      {
        main_question: {
          code: "PQ08",
          icon_class: "alcohol",
          title: "Alcohol",
          status: false,
          description: "Do you drink Alcohol (per week)?",

          member_data: [
            {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Liquor (ml)",
                  value: "",
                  warning: false,
                  field_type: "textField",
                  dropdown_data: [
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Liquor (ml)",
                  value: "",
                  warning: false,
                  field_type: "textField",
                  dropdown_data: [
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Liquor (ml)",
                  value: "",
                  warning: false,
                  field_type: "textField",
                  dropdown_data: [
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Liquor (ml)",
                  value: "",
                  warning: false,
                  field_type: "textField",
                  dropdown_data: [
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Liquor (ml)",
                  value: "",
                  warning: false,
                  field_type: "textField",
                  dropdown_data: [
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ],
                  numbers_only: false,
                },
              ],
            },
            {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Liquor (ml)",
                  value: "",
                  warning: false,
                  field_type: "textField",
                  dropdown_data: [
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ],
                  numbers_only: false,
                },
              ],
            },
          ],
        },
        sub_question: [],
      },
    ],
  },

  MEDICAL_QUESTION_DATA_GO_DIGIT: {
    question: [
      {
        main_question: {
          code: "PQ01",
          icon_class: "tobaco",
          title: "Existing cover",
          description:
            "Do you have any existing life insurance cover of premium paying and/ or paid-up policies?",
          status: false,
          member_data: [
            {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [],
            },
            {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [],
            },
            {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [],
            },
            {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [],
            },
            {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [],
            },
            {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [],
            },
          ],
        },
      },
      {
        main_question: {
          code: "PQ08",
          icon_class: "hospitalized",
          title: "Hospitalised",
          status: false,
          member_data: [],
        },
        sub_question: [
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Hypertension?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Diabetes?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Asthma?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Thyroid?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have any Gynaecological problem ?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Hyperlipidemia?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title:
              "Do you face any symptoms like chest pain, fatigue, weight loss, dizziness, joint pain, change in bowel habit, difficulty in breathing, pain in abdomen, bleeding/pain while passing stools etc?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        main_question: {
          code: "PQ08",
          icon_class: "hospitalized",
          title: "Intoxication Declaration",
          status: false,
          member_data: [],
        },
        sub_question: [
          {
            code: "PQ08-SQ01",
            icon_class: "alcohol",
            title: "Do you consume Alcohol?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you consume tobacco?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  MEDICAL_QUESTION_DATA_FUTURE_GENERALI: {
    HQ01: {
      main_question: {
        id: "HQ01",
        desc: "Does any person(s) to be insured has any Pre-Existing illness?",
        status: false,
        icon_class: "preexisting",
        title: "Pre-Exisiting Diseases",
      },
      sub_question: {
        SQ01: {
          id: "SQ01",
          desc: "",
          status: false,
          icon_class: "",
          title:
            "Is the person already have Health Total policy of Future Generali?",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
          },
        },
        SQ02: {
          id: "SQ02",
          desc: "",
          status: false,
          icon_class: "diabates",
          title:
            "Is the person have at present or in the past had any health complaints, signs or symptoms, or were taking treatment or were hospitalized for any illness.",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO72",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO82",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO92",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO102",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO112",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO122",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
          },
        },
        SQ03: {
          id: "SQ03",
          desc: "",
          status: false,
          icon_class: "",
          title:
            "Is the person at present or in the past met with any accident / injury or were hospitalized or taking treatment for any accident injury?",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO73",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO83",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO93",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO103",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO113",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO123",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
          },
        },
        SQ04: {
          id: "SQ04",
          desc: "",
          status: false,
          icon_class: "MO25",
          title:
            "Is the person undergone any surgery in the past or going for any planned surgery at present / recent future?",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO74",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO84",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO94",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO104",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO114",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO124",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
          },
        },
        SQ05: {
          id: "SQ05",
          desc: "",
          status: false,
          icon_class: "MO25",
          title: "Whether any Health Insurance Policy has been declined?",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO75",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO85",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO95",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO105",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO115",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO125",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ label: "", value: "" }],
                },
              ],
            },
          },
        },
      },
    },
    HQ03: {
      main_question: {
        id: "HQ03",
        desc: "Diabetes (up to + 15 mg/dl above *Normal range ) 10% , Diabetes (+16 mg/dl to + 30 mg/dl above *Normal range) 20%",
        status: false,
        title: "Diabetes?",
        icon_class: "medicines",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO31",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO32",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO33",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO34",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO35",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO36",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ05: {
      main_question: {
        id: "HQ05",
        desc: "Hypertension (140/90mmHg) 10% , Hypertension ( 141 to 150 mmHg Systolic / 91 to 100 mm Hg diastolic ) 20%",
        status: false,
        title: "Hypertension?",
        icon_class: "headache",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO310",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO320",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO330",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO340",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO350",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO360",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ06: {
      main_question: {
        id: "HQ06",
        desc: "Is the person in good health and free from physical and mental disease or infirmity or medical complaints or deformity?",
        status: false,
        title: "Good Health",
        icon_class: "goodhealth",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO311",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO321",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO331",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO341",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO351",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO361",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ07: {
      main_question: {
        id: "HQ07",
        desc: "Is the person have at present or in the past had any health complaints, signs or symptoms, or were taking treatment or were hospitalized for any illness?",
        status: false,
        title: "Hospitalization",
        icon_class: "hospitalized",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO312",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO322",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO332",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO342",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO352",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO362",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ08: {
      main_question: {
        id: "HQ08",
        desc: "Is the person at present or in the past met with any accident / injury or were hospitalized or taking treatment for any accident injury?",
        status: false,
        title: "Accident Injury",
        icon_class: "treatment",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO313",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO323",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO333",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO343",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO353",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO363",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ09: {
      main_question: {
        id: "HQ09",
        desc: "Is the person undergone any surgery in the past or going for any planned surgery at present / recent future?",
        status: false,
        title: "Surgery",
        icon_class: "treatment",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO314",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO324",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO334",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO344",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO354",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO364",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ10: {
      main_question: {
        id: "HQ10",
        desc: "Any of the insured persons is pregnant?",
        status: false,
        title: "Pregnancy",
        icon_class: "medicalhistory",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO315",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO325",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO335",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO345",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO355",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO365",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ11: {
      main_question: {
        id: "HQ11",
        desc: "Is the person Non-Indian resident?",
        status: false,
        title: "Indian",
        icon_class: "treatment",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO316",
            selection_status: false,
            field_data: [],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO326",
            selection_status: false,
            field_data: [],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO336",
            selection_status: false,
            field_data: [],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO346",
            selection_status: false,
            field_data: [],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO356",
            selection_status: false,
            field_data: [],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO366",
            selection_status: false,
            field_data: [],
          },
        },
      },
    },
    HQ02: {
      main_question: {
        id: "HQ02",
        desc: "Do any of the members smoke?",
        status: false,
        title: "Somking",
        icon_class: "smoking",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO25",
            selection_status: false,
            field_data: [
              {
                field_type: "DROPDOWN",
                dropdown_data: [{ label: "", value: "" }],
              },
            ],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO26",
            selection_status: false,
            field_data: [
              {
                field_type: "DROPDOWN",
                dropdown_data: [{ label: "", value: "" }],
              },
            ],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO27",
            selection_status: false,
            field_data: [
              {
                field_type: "DROPDOWN",
                dropdown_data: [{ label: "", value: "" }],
              },
            ],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO28",
            selection_status: false,
            field_data: [
              {
                field_type: "DROPDOWN",
                dropdown_data: [{ label: "", value: "" }],
              },
            ],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO29",
            selection_status: false,
            field_data: [
              {
                field_type: "DROPDOWN",
                dropdown_data: [{ label: "", value: "" }],
              },
            ],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO30",
            selection_status: false,
            field_data: [
              {
                field_type: "DROPDOWN",
                dropdown_data: [{ label: "", value: "" }],
              },
            ],
          },
        },
      },
    },
  },
  MEDICAL_QUESTION_DATA_STAR: {
    question: [
      {
        main_question: {
          code: "PQ08",
          icon_class: "hospitalized",
          title: "Hospitalised",
          description:
            "Do you have any existing life insurance cover of premium paying and/ or paid-up policies?",
          status: false,
          member_data: [],
        },
        sub_question: [
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Hypertension?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complications with high lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Diabetes?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Diabetes?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Insulin dependent",
                        value: "Insulin dependent",
                      },
                      {
                        label: "Non Insulin dependent",
                        value: "Non Insulin dependent",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was FBS value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Abnormal", value: "Abnormal" },
                      {
                        label: "Within normal limits",
                        value: "Within normal limits",
                      },
                      {
                        label: "No listed",
                        value: "No listed",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Insulin", value: "Insulin" },
                      { label: "Oral Medication", value: "Oral Medication" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Asthma?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How many times a year?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Less than 2", value: "Less than 2" },
                      { label: "More than 5", value: "More than 5" },
                      { label: "2 to 5", value: "2 to 5" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Were you hospitalized due to Asthma?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of hospitalization?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any complication due to Asthma ?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Thyroid?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "10 yrs or more", value: "10 yrs or more" },
                      { label: "Less than 5 yrs", value: "Less than 5 yrs" },
                      { label: "5 to 9 yrs", value: "5 to 9 yrs" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Type of Thyroid dysfunction?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Hyperthyroidism", value: "Hyperthyroidism" },
                      { label: "Hypothyroidism", value: "Hypothyroidism" },
                      { label: "Not sure", value: "Not sure" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have any Gynaecological problem ?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "What was HbA1c value?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 7.5%", value: "Upto 7.5%" },
                      { label: "More than 7.5%", value: "More than 7.5%" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Number of tablets",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "More than two", value: "More than two" },
                      { label: "Two or less", value: "Two or less" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you have Hyperlipidemia?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any related to hyperlipidemia?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Any records of complications?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you hospitalized?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "On medication for High lipids?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title:
              "Do you face any symptoms like chest pain, fatigue, weight loss, dizziness, joint pain, change in bowel habit, difficulty in breathing, pain in abdomen, bleeding/pain while passing stools etc?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Systolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "More than 130 to 149 mm of Hg",
                        value: "More than 130 to 149 mm of Hg",
                      },
                      {
                        label: "130 mm of Hg or Less",
                        value: "130 mm of Hg or Less",
                      },
                      {
                        label: "150 mm of Hg or More",
                        value: "150 mm of Hg or More",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Diastolic Reading?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "90 mm of Hg or less",
                        value: "90 mm of Hg or less",
                      },
                      {
                        label: "90 to 95 mm of Hg",
                        value: "90 to 95 mm of Hg",
                      },
                      {
                        label: "More than95 mm of Hg",
                        value: "More than95 mm of Hg",
                      },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        main_question: {
          code: "PQ08",
          icon_class: "hospitalized",
          title: "Intoxication Declaration",
          status: false,
          member_data: [],
        },
        sub_question: [
          {
            code: "PQ08-SQ01",
            icon_class: "alcohol",
            title: "Do you consume Alcohol?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "How often do you consume alchohol?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      {
                        label: "Occasional or Social",
                        value: "Occasional or Social",
                      },
                      { label: "Once in a week", value: "Once in a week" },
                      { label: "Daily", value: "Daily" },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "When was it diagnosed?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Upto 10 yrs", value: "Upto 10 yrs" },
                      {
                        label: "More than 10 yrs",
                        value: "More than 10 yrs",
                      },
                    ],
                    numbers_only: false,
                  },
                  {
                    code: "PQ08-SQ01-1",
                    title: "Current Medication?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "None", value: "None" },
                      { label: "Non steroids", value: "Non steroids" },
                      { label: "steroids", value: "steroids" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            code: "PQ08-SQ01",
            icon_class: "tobaco",
            title: "Do you consume tobacco?",
            status: false,
            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "son",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "son",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "son",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "son",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Do you consumes tobacco?",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "Chewable", value: "Chewable" },
                      { label: "Smoke", value: "Smoke" },
                      { label: "Both", value: "Both" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  MEDICAL_QUESTION_DATA_CARE: {
    MQ01: {
      main_question: {
        id: "MQ01",
        icon_class: "tobaco",
        title: "Pre-Existing Diseases",
        description:
          "Does any person(s) to be insured has any Pre-Existing diseases?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",

            name: "",
            image_class: "self",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",

            name: "",
            image_class: "spouse",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",

            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",

            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            selection_status: false,
          },
        },

        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Diabetes Mellitus type 1 or Diabetes on insulin or Diabetes associated with blindness or chronic foot ulcer?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
                selection_status: false,
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Are you suffering from Hypertension/High Blood Pressure?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F2": {
            //     id: "MQ1-SQ1-F2",
            //     title: "Existing Since",
            //     field_type: "DATEPICKER",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ3-SQ3": {
            id: "MQ3-SQ3",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from Liver Diseases?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F3": {
            //     id: "MQ1-SQ1-F3",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ4-SQ4": {
            id: "MQ4-SQ4",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from HIV/AIDS/STD?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F4": {
            //     id: "MQ1-SQ1-F4",
            //     title: "Existing Since",
            //     field_type: "DATEPICKER",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ5-SQ5": {
            id: "MQ5-SQ5",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from Cancer?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F5": {
            //     id: "MQ1-SQ1-F5",
            //     title: "Existing Since",
            //     field_type: "DATEPICKER",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ6-SQ6": {
            id: "MQ6-SQ6",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from Cardiac Disease?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F6": {
            //     id: "MQ1-SQ1-F6",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ7-SQ7": {
            id: "MQ7-SQ7",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from Arthritis/Joint pain?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ8-SQ8": {
            id: "MQ8-SQ8",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from Kidney Disease?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ9-SQ9": {
            id: "MQ9-SQ9",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from Paralysis/stroke?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ10-SQ10": {
            id: "MQ10-SQ10",
            icon_class: "",
            status: false,
            title: "",
            description: "Are you suffering from Congenital Disorder?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ11-SQ11": {
            id: "MQ11-SQ11",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Any Respiratory disease / Disease of Lungs, Pleura and airway (including but not limited to Asthma / Tuberculosis / Pleural effusion / Bronchitis / Emphysema)?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ12-SQ12": {
            id: "MQ12-SQ12",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Any disorders of the endocrine system (including but not limited to Pituitary / Parathyroid / adrenal gland disorders)?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ13-SQ13": {
            id: "MQ13-SQ13",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Has any of the Proposed to be Insured consulted/taken treatment or recommended to take investigations/medication/surgery other than for childbirth/minor injuries?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ14-SQ14": {
            id: "MQ14-SQ14",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Has any of the Proposed to be Insured been hospitalized or has been under any prolonged treatment for any illness/injury or has undergone surgery other than for childbirth/minor injuries?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ15-SQ15": {
            id: "MQ15-SQ15",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Do You smoke, consume alcohol, or chew tobacco, ghutka or paan or use any recreational drugs? If ‘Yes’ then please provide the frequency & amount consumed?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ15-SQ15-F1": {
                    id: "MQ15-SQ15-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ15-SQ15-F2": {
                    id: "MQ15-SQ15-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ15-SQ15-F1": {
                    id: "MQ15-SQ15-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ15-SQ15-F2": {
                    id: "MQ15-SQ15-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ15-SQ15-F1": {
                    id: "MQ15-SQ15-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ15-SQ15-F2": {
                    id: "MQ15-SQ15-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ15-SQ15-F1": {
                    id: "MQ15-SQ15-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ15-SQ15-F2": {
                    id: "MQ15-SQ15-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ15-SQ15-F1": {
                    id: "MQ15-SQ15-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ15-SQ15-F2": {
                    id: "MQ15-SQ15-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ15-SQ15-F1": {
                    id: "MQ15-SQ15-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ15-SQ15-F2": {
                    id: "MQ15-SQ15-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ16-SQ16": {
            id: "MQ16-SQ16",
            icon_class: "",
            status: false,
            title: "",
            description: "Any other diseases or ailments not mentioned above?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                field_data: {
                  "MQ16-SQ16-F1": {
                    id: "MQ16-SQ16-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",

                    value: { value: "", warning: false },
                  },
                  "MQ16-SQ16-F2": {
                    id: "MQ16-SQ16-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                field_data: {
                  "MQ16-SQ16-F1": {
                    id: "MQ16-SQ16-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",

                    value: { value: "", warning: false },
                  },
                  "MQ16-SQ16-F2": {
                    id: "MQ16-SQ16-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ16-SQ16-F1": {
                    id: "MQ16-SQ16-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",

                    value: { value: "", warning: false },
                  },
                  "MQ16-SQ16-F2": {
                    id: "MQ16-SQ16-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ16-SQ16-F1": {
                    id: "MQ16-SQ16-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",

                    value: { value: "", warning: false },
                  },
                  "MQ16-SQ16-F2": {
                    id: "MQ16-SQ16-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ16-SQ16-F1": {
                    id: "MQ16-SQ16-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",

                    value: { value: "", warning: false },
                  },
                  "MQ16-SQ16-F2": {
                    id: "MQ16-SQ16-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",

                image_class: "son",
                selection_status: false,
                field_data: {
                  "MQ16-SQ16-F1": {
                    id: "MQ16-SQ16-F1",
                    title: "Existing Since",
                    field_type: "DATEPICKER",

                    value: { value: "", warning: false },
                  },
                  "MQ16-SQ16-F2": {
                    id: "MQ16-SQ16-F2",
                    title: "Description",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F8": {
            //     id: "MQ1-SQ1-F8",
            //     field_type: "DATEPICKER",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
        },
      },
    },
    MQ02: {
      main_question: {
        id: "MQ02",
        icon_class: "tobaco",
        title: "Hospitalized",
        description:
          "Has any of the new person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",

            image_class: "son",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            selection_status: false,
          },
        },
      },
    },
    MQ03: {
      main_question: {
        id: "MQ03",
        icon_class: "tobaco",
        title: "claim",
        description:
          "Has any of the new person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",

            image_class: "son",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            selection_status: false,
          },
        },
      },
    },
    MQ04: {
      main_question: {
        id: "MQ04",
        icon_class: "tobaco",
        title: "Declined/Cancelled",
        description:
          "Has any proposal for Health Insurance of the new person(s) to be insured, been declined, cancelled or charged a higher premium?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",

            image_class: "son",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            selection_status: false,
          },
        },
      },
    },
    MQ05: {
      main_question: {
        id: "MQ05",
        icon_class: "tobaco",
        title: "Other health insurance policy",
        description:
          "Is any of the person(s) to be insured, already covered under any other health insurance policy of Religare Health Insurance?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",

            image_class: "son",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            selection_status: false,
          },
        },
      },
    },
  },

  MEDICAL_QUESTION_DATA_HDFC: {
    MQ01: {
      main_question: {
        id: "MQ01",
        icon_class: "tobaco",
        title: "",
        description: "",
        status: false,

        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "Pre-Existing Diseases",
            description: "Any other diseases or ailments not mentioned above?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_four: {
                keyName: "child_four",
                name: "",

                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "Smoking",
            description: "Does any of the members Smoke?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "self",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "spouse",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
              child_four: {
                keyName: "child_four",
                name: "",

                image_class: "son",
                selection_status: false,
                // field_data: {
                //   "MQ1-SQ1-F1": {
                //     id: "MQ1-SQ1-F1",
                //     title: "Description",
                //     field_type: "TEXTFIELD",
                //     value: { value: "", warning: false },
                //   },
                // },
              },
            },
          },
        },
      },
    },
  },
  PAGE_STATUS: false,
  Preview_Loader: false,
  Form_dob_model: false,
  Form_member_model: false,
};

let ageData = [];
for (let i = 18; i <= 110; i++) {
  ageData.push({
    value: `${i}`,
    label: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.AGE = ageData;

// let SUM_INSURED_DATA = [];

// for (let i = 100000; i <= 7500000; i += i < 1000000 ? 100000 : 500000) {
//   SUM_INSURED_DATA.push({
//     value: `${i / 100000} Lakh`,
//     label: `${formatNumberToLakhOrCrores(i)}`,
//   });
//   if (i === 10000000) {
//     SUM_INSURED_DATA.push(
//       { value: "1 Crore", label: formatNumberToLakhOrCrores(10000000) },
//       { value: "1.5 Crore", label: formatNumberToLakhOrCrores(15000000) },
//       { value: "2 Crore", label: formatNumberToLakhOrCrores(20000000) },
//       { value: "3 Crore", label: formatNumberToLakhOrCrores(30000000) },
//       { value: "6 Crore", label: formatNumberToLakhOrCrores(60000000) }
//     );
//   }
// }

let SUM_INSURED_DATA = [];
for (
  let i = 100000;
  i <= 15000000;
  i += i < 2000000 ? 100000 : i < 5000000 ? 500000 : 1000000
) {
  SUM_INSURED_DATA.push({
    value: `${i}`,
    label: `${formatNumberToLakhOrCrores(i)}`,
  });
}

SUM_INSURED_DATA.push(
  { value: "10000000", label: formatNumberToLakhOrCrores(10000000) },
  { value: "15000000", label: formatNumberToLakhOrCrores(15000000) },
  { value: "20000000", label: formatNumberToLakhOrCrores(20000000) },
  { value: "30000000", label: formatNumberToLakhOrCrores(30000000) },
  { value: "60000000", label: formatNumberToLakhOrCrores(60000000) }
);
initialState.DROPDOWN_DATA.SUM_INSURED = SUM_INSURED_DATA;

export const HealthSlice = createSlice({
  name: "Health",
  initialState,
  reducers: HEALTH_REDUCERS,
});

export default HealthSlice.reducer;

import { PayloadAction } from "@reduxjs/toolkit";

import {
  DROPDOWN_MASTER,
  INSURED_MEMBER_DETAILS,
  THealthAddForm,
  THealthNomineeDetails,
  THealthSlice,
  TkycDetails,
} from "../../../types/Health/THealthSlice";
import { TDropdown } from "../../../types/Common/TDropdown";
import { THealthFormResponse } from "../../../types/Health/Form/THealthFormResponse";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { THealthBuyActionResopnse } from "../../../types/Health/HealthQuotation/THealthBuyActionResponse";
import {
  TAddressDetails,
  TAdultOneDetails,
  TOtherMemberDetails,
} from "../../../types/Health/ProposalDeatail/TProposalDetail";
import { TCareAddon } from "../../../types/Term/TCareAddon";

function BULK_UPLOAD(state: THealthSlice, action: PayloadAction<THealthSlice>) {
  const data: THealthSlice = { ...state, ...action.payload };

  return data;
}
function SET_HEALTH_SLICE(
  state: THealthSlice,
  action: PayloadAction<THealthSlice>
) {
  let data: THealthSlice = action.payload;
  return data;
}

function setAddFormData(
  state: THealthSlice,
  action: PayloadAction<THealthAddForm>
) {
  return {
    ...state,
    ADD_FORM: { ...state.ADD_FORM, ...action.payload },
  };
}

function updateMultipleKeysInAddForm(
  state: THealthSlice,
  action: PayloadAction<Partial<THealthSlice["ADD_FORM"]>>
) {
  state.ADD_FORM = {
    ...state.ADD_FORM,
    ...action.payload,
  };
}

function updateMultipleKeysInAddFormResponse(
  state: THealthSlice,
  action: PayloadAction<Partial<THealthSlice["ADD_FORM_RESPONSE"]>>
) {
  state.ADD_FORM_RESPONSE = {
    ...state.ADD_FORM_RESPONSE,
    ...action.payload,
  };
}

function setAddFormResponse(
  state: THealthSlice,
  action: PayloadAction<THealthFormResponse>
) {
  return { ...state, ADD_FORM_RESPONSE: action.payload };
}

function setAddFormResponseUpdateOneKey(
  state: THealthSlice,
  action: PayloadAction<{ key: keyof THealthFormResponse; value: any }>
) {
  const { key, value } = action.payload;
  return {
    ...state,
    ADD_FORM_RESPONSE: {
      ...state.ADD_FORM_RESPONSE,
      [key]: value,
    },
  };
}

const SAVE_ALL_QUOTES_DATA = (
  state: THealthSlice,
  action: PayloadAction<THealthQuote[]>
) => {
  const data = {
    ...state,
    ALL_QUOTES_DATA: action.payload,
  };

  return data;
};

const SAVE_SELECTED_QUOTE_DATA = (
  state: THealthSlice,
  action: PayloadAction<THealthQuote>
) => {
  console.log("qqqqq", action.payload);
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: action.payload,
  };

  return data;
};

const SAVE_BUYACTION_RESPONSE_DATA = (
  state: THealthSlice,
  action: PayloadAction<THealthBuyActionResopnse>
) => {
  const data = {
    ...state,
    BUYACTION_RESPONSE_DATA: action.payload,
  };

  return data;
};

type KEY_MEMBER_FORM_DATA = keyof INSURED_MEMBER_DETAILS;
const SAVE_INSURED_MEMBER_DETAILS = (
  state: THealthSlice,
  action: PayloadAction<{
    key: KEY_MEMBER_FORM_DATA;
    value: TOtherMemberDetails | TAdultOneDetails | THealthNomineeDetails;
  }>
) => {
  const { key, value } = action.payload;
  const data: THealthSlice = {
    ...state,
    INSURED_MEMBER_DETAILS: { ...state.INSURED_MEMBER_DETAILS, [key]: value },
  };
  return data;
};

const SAVE_ADDRESS_DETAILS = (
  state: THealthSlice,
  action: PayloadAction<TAddressDetails>
) => {
  const data: THealthSlice = {
    ...state,
    ADDRESS_DETAILS: { ...action.payload },
  };
  return data;
};

const SAVE_MEDICAL_QUESTION_DATA_IIFCO = (
  state: THealthSlice,
  action: PayloadAction<any>
) => {
  const data = {
    ...state,
    MEDICAL_QUESTION_DATA_IIFCO: action.payload,
  };

  return data;
};
const SAVE_MEDICAL_QUESTION_DATA_GO_DIGIT = (
  state: THealthSlice,
  action: PayloadAction<any>
) => {
  const data = {
    ...state,
    MEDICAL_QUESTION_DATA_GO_DIGIT: action.payload,
  };

  return data;
};
const SAVE_MEDICAL_QUESTION_DATA_FUTURE_GENERALI = (
  state: THealthSlice,
  action: PayloadAction<any>
) => {
  const data = {
    ...state,
    MEDICAL_QUESTION_DATA_FUTURE_GENERALI: action.payload,
  };

  return data;
};
const SAVE_MEDICAL_QUESTION_DATA_STAR = (
  state: THealthSlice,
  action: PayloadAction<any>
) => {
  const data = {
    ...state,
    MEDICAL_QUESTION_DATA_STAR: action.payload,
  };

  return data;
};

const UPDATE_KYC_DETAILS = (
  state: THealthSlice,
  action: PayloadAction<TkycDetails>
) => {
  const data = {
    ...state,

    KYC_DETAILS: action.payload,
  };

  return data;
};

const UPDATE_QUOTE_LOADER_STATUS = (
  state: THealthSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    QUOTE_LOADER: action.payload,
  };

  return data;
};
const CHANGE_PAGE_STATUS = (
  state: THealthSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    PAGE_STATUS: action.payload,
  };

  return data;
};

type COMMON_DROPDOWN_DATA_API = keyof DROPDOWN_MASTER;
const HEALTH_DROPDOWNDATA = (
  state: THealthSlice,
  action: PayloadAction<{ key: COMMON_DROPDOWN_DATA_API; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  let data = { ...state };
  data = {
    ...state,
    DROPDOWN_DATA: {
      ...state.DROPDOWN_DATA,
      [key]: value,
    },
  };
  return data;
};

const CHANGE_NOMINEE_STATUS = (
  state: THealthSlice,
  action: PayloadAction<"ADULT_2" | "OTHER">
) => {
  let data = { ...state };
  data = {
    ...state,
    NOMINEE_RELATIONSHIP_STATUS: action.payload,
  };
  return data;
};
const ADDON_DETAILS_DATA = (
  state: THealthSlice,
  action: PayloadAction<TCareAddon>
) => {
  console.log("action.payload", action.payload);
  let data = { ...state };
  data = {
    ...data,
    ADDON_DETAILS: action.payload,
  };
  return data;
};
const PREVIEW_LOADER = (
  state: THealthSlice,
  action: PayloadAction<boolean>
) => {
  let data = { ...state };
  data = {
    ...data,
    Preview_Loader: action.payload,
  };
  return data;
};
const SAVE_MEDICAL_QUESTION_DATA_CARE = (
  state: THealthSlice,
  action: PayloadAction<any>
) => {
  const data = {
    ...state,
    MEDICAL_QUESTION_DATA_CARE: action.payload,
  };

  return data;
};
const SAVE_MEDICAL_QUESTION_DATA_HDFC = (
  state: THealthSlice,
  action: PayloadAction<any>
) => {
  const data = {
    ...state,
    MEDICAL_QUESTION_DATA_HDFC: action.payload,
  };

  return data;
};
const FORM_DOB_MODEL = (
  state: THealthSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    Form_dob_model: action.payload,
  };

  return data;
};
const FORM_MEMBER_MODEL = (
  state: THealthSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,
    Form_member_model: action.payload,
  };
  console.log("data66", data);
  return data;
};

const ADDFORM_DATA = (
  state: THealthSlice,
  action: PayloadAction<THealthAddForm>
) => {
  let data = { ...state };
  data = {
    ...state,
    ADD_FORM: action.payload,
  };
  return data;
};

const SET_PAGE_STATUS=(state : THealthSlice, action : PayloadAction<1 |2>) =>{
  state.ADD_FORM.page_status = action.payload;
}

export const HEALTH_REDUCERS = {
  SET_PAGE_STATUS,
  BULK_UPLOAD,
  SET_HEALTH_SLICE,
  ADDFORM_DATA,
  setAddFormData,
  setAddFormResponse,
  FORM_MEMBER_MODEL,
  SAVE_ALL_QUOTES_DATA,
  ADDON_DETAILS_DATA,
  SAVE_BUYACTION_RESPONSE_DATA,
  SAVE_INSURED_MEMBER_DETAILS,
  SAVE_ADDRESS_DETAILS,
  SAVE_MEDICAL_QUESTION_DATA_IIFCO,
  SAVE_MEDICAL_QUESTION_DATA_GO_DIGIT,
  SAVE_MEDICAL_QUESTION_DATA_FUTURE_GENERALI,
  SAVE_MEDICAL_QUESTION_DATA_STAR,
  SAVE_MEDICAL_QUESTION_DATA_CARE,
  SAVE_MEDICAL_QUESTION_DATA_HDFC,
  HEALTH_DROPDOWNDATA,
  SAVE_SELECTED_QUOTE_DATA,
  CHANGE_PAGE_STATUS,
  CHANGE_NOMINEE_STATUS,
  PREVIEW_LOADER,
  FORM_DOB_MODEL,
  UPDATE_QUOTE_LOADER_STATUS,
  setAddFormResponseUpdateOneKey,
  UPDATE_KYC_DETAILS,
  updateMultipleKeysInAddForm,
  updateMultipleKeysInAddFormResponse,
};

export type HealthReducers = typeof HEALTH_REDUCERS;
